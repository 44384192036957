import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import SunnysideGrid from '../../../components_sunnyside/grid';
import SunnysideLink from '../../../components_sunnyside/link';
import { colors } from '../../../styles/_colors';
import useWindowSize from '../../../utils/useWindowSize';
interface SunnysideErrorContentProps {
    errorCode: string,
    subtitle?: string,
}
export default function SunnysideErrorContent(props: SunnysideErrorContentProps): JSX.Element {
    const { errorCode } = props;
    const { width } = useWindowSize();
    const Title = styled('h1')(({ theme }) => ({
        color: '#C1C1C1',
        fontFamily: 'Good Sans Regular',
        fontSize: '0.875rem',
        lineHeight: '1.875rem',
        fontWeight: 700,
        letterSpacing: '0.08em',
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '2.5rem',
            textAlign: 'center'
        },
    }));
    
    return (
        <SunnysideGrid 
            maxwidthgrid
            display='flex'
            flexDirection='column'
            alignItems='center'>
            <Title>
                {errorCode}
            </Title>
            <Typography variant='h2'
                paddingBottom='2rem'
                textAlign='center'>
                Sorry we couldn&apos;t find that page
            </Typography>
            <Typography variant='body2'
                textAlign='center'
                color={colors.pine}
                width={width > 900 ? '38rem' : '100%'}>
                Please email us at&nbsp;
                <SunnysideLink 
                    href='mailto:support@sunnyside.co'
                    fontVariant='body2'
                    fontFamily='Good Sans Light'>
                    support@sunnyside.co
                </SunnysideLink>
                &nbsp;if there&apos;s something specific we can help with.
            </Typography>
            <Box height='5rem' />
        </SunnysideGrid>
    );
}