import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Image from '../../../components_sunnyside/image';
import NewYorkTimesLogo from '../../../../public/img/sunnyside/new_york_times_logo.png';
import ForbesLogo from '../../../../public/img/sunnyside/forbes_logo.png';
import USATodayLogo from '../../../../public/img/sunnyside/usa_today_logo.png';
import MashableLogo from '../../../../public/img/sunnyside/mashable_logo.png';
import { colors } from '../../../styles/_colors';
import useWindowSize from '../../../utils/useWindowSize';
import MaxWidthBox from '../../../components_sunnyside/max_width_box';
import SunnysideGrid, { SunnysideGridProps } from '../../../components_sunnyside/grid';

export default function FooterFeatured(): JSX.Element {
    const { width } = useWindowSize();
    const ChildGrid = styled(SunnysideGrid)<SunnysideGridProps>(({ theme }) => ({
        background: colors.beige,
        display: 'flex',
        [theme.breakpoints.down(1050)]: {
            padding: '0rem 1.25rem'
        },
        [theme.breakpoints.up(1050)]: {
            height: '16rem',
        },
    }));

    return (
        <ChildGrid>
            <MaxWidthBox display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'>
                <Typography variant='thinner_h2'>
                    Featured On
                </Typography>
                <Box display='flex'
                    width='100%'
                    height={width < 1050 ? '70%' : '100%'}
                    flexDirection={width < 1050 ? 'column' : 'row'}
                    justifyContent='center'
                    alignItems='center'
                    paddingTop={width < 1050 ? '1rem' : '2rem'}>
                    <Box paddingRight={width > 1050 && '3rem'}
                        paddingBottom={width < 1050 && '2.5rem'}
                        paddingTop={width < 1050 && '2.5rem'}>
                        <Image src={NewYorkTimesLogo}
                            alt='New York Times Logo' 
                            width={322}
                            height={60}
                            objectFit='cover'/>
                    </Box>
                    <Box paddingRight={width > 1050 && '3rem'}
                        paddingBottom={width < 1050 && '2.5rem'}>
                        <Image src={ForbesLogo}
                            alt='Forbes Logo' 
                            width='143'
                            height='36'
                            objectFit='cover'/>
                    </Box>
                    <Box paddingRight={width > 1050 && '3rem'}
                        paddingBottom={width < 1050 && '2.5rem'}>
                        <Image src={USATodayLogo}
                            alt='USA Today Logo' 
                            width='253'
                            height='34'
                            objectFit='cover'/>
                    </Box>
                    <Box paddingBottom={width < 1050 && '2.5rem'}>
                        <Image src={MashableLogo}
                            alt='Mashable Logo' 
                            width='148'
                            height='26'
                            objectFit='cover'/>
                    </Box>
                </Box>
            </MaxWidthBox>
        </ChildGrid>
    );
}