import { Box, Typography, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';

import Image from '../../../components_sunnyside/image';
import FooterCTABanner from '../../../../public/img/sunnyside/footer_cta_banner.png';
import { colors } from '../../../styles/_colors';
import SunnysideButton from '../../../components_sunnyside/button';
import useWindowSize from '../../../utils/useWindowSize';
import SunnysideGrid, { SunnysideGridProps } from '../../../components_sunnyside/grid';
import PromotionButton from '../../../components_sunnyside/promotion_button';
import { diagnosticLink } from '../../../utils/diagnosticLinks';

interface BrightestSectionProps {
    background?: any,
    diagnosticLink?: string
}

export default function BrightestSection(props: BrightestSectionProps): JSX.Element {
    const router = useRouter();
    const pathname = router.asPath;
    const { width } = useWindowSize();
    const ChildGrid = styled(SunnysideGrid)<SunnysideGridProps>(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '0rem !important',
        background: colors.sunny,
        [theme.breakpoints.down('lg')]: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '0rem !important',
        },
    }));
    const ImageBox = styled(Box)<BoxProps>(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '55%',
        },
    }));

    return (
        <ChildGrid maxwidthwrapper>
            <Box width='100%'
                display='flex'
                flexDirection={width > 1200 ? 'row' : 'column-reverse'}
                justifyContent={width > 1200 ? 'flex-end' : 'center'}
                alignItems={width < 1200 && 'center'}
                height={width > 1200 && '27rem'}>
                <ImageBox>
                    <Image src={FooterCTABanner}
                        alt='footer cta banner' 
                        width={width > 1200 ? 478 : 387}
                        height={width > 1200 ? 604 : 343}
                        objectFit='cover'
                        objectPosition='top'/>
                </ImageBox>
                <Box width={width > 1200 ? '45%' : '100%'}
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems={width > 1200 ? 'flex-start' : 'center'}
                    padding={width > 1200 ? '7rem 0rem 5rem 0rem' : '0rem 0rem 1.25rem 0rem'}>
                    <Typography variant='h2'
                        paddingTop='2rem'
                        paddingBottom='3rem'
                        textAlign={width > 1200 ? 'left' : 'center'}
                        width={ width > 1200 ? '31.5rem' : '100%'}>
                        Start building healthier drinking habits today.
                    </Typography>
                    {pathname === '/ritual' ?
                        <PromotionButton 
                            buttonText='Get Your Custom Plan'
                            buttonUrl={diagnosticLink()}
                            passQueryToUrl
                            promotionText='20% exclusive discount included'/>
                        :
                        <SunnysideButton url={props.diagnosticLink || diagnosticLink()} passQueryToUrl>
                            Take the 3 Min Quiz
                        </SunnysideButton>}
                </Box>
            </Box>
        </ChildGrid>
    );  
}