import Head from 'next/head';

import { colors } from '../../styles/_colors';
import SunnysideHeader from '../../components_sunnyside/header';
import BrightestSection from '../../sections/reusable/brightest';
import FooterFeatured from '../../sections/reusable/footer_featured';
import SunnysideFooter from '../../components_sunnyside/footer';
import { GetStaticProps } from 'next';
import SunnysideErrorContent from '../../sections/error/error_content';
import SunnysideWrapper from '../../components_sunnyside/wrapper';
import SunnysideMain from '../../components_sunnyside/main';

export const getStaticProps: GetStaticProps = async () => {
    return {
        props: {
            metaData: {
                ogTitle: 'Sunnyside - 404',
                ogSiteName: 'Sunnyside - 404',
                ogUrl: 'https://www.sunnyside.co/',
                ogDescription: '',
                ogImage: 'https://images.prismic.io/sunnysidetest/63e6349a-7e7b-4ce2-9841-54c570ebcda7_sunnyside-og.jpg?auto=compress,format',
                title: 'Sunnyside',
            }
        },
        revalidate: 30
    };
};

export default function Sunnyside404Page(): JSX.Element {
    return (
        <SunnysideWrapper width='100%'>
            <Head>
                <meta 
                    name="description" 
                    content="Mindful drinking is about getting more out of life, not less. Learn about our values, the science, our team, and the benefits our members experience." />
                <link rel="canonical" href="https://www.sunnyside.co/about" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@joinsunnyside" />
                <meta name="twitter:creator" content="@joinsunnyside" />
            </Head>
            <SunnysideHeader bgcolor={colors.cloud}/>
            <SunnysideMain>
                <SunnysideErrorContent errorCode='404'/>
                <BrightestSection background={colors.cloud}/>
                <FooterFeatured />
            </SunnysideMain>
            <SunnysideFooter />
        </SunnysideWrapper>
    );
}